import React,{useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import anime from 'animejs/lib/anime.es.js';
import Illu from '../svgs/Lost.inline.svg'
import '../styles/404.scss'

const NotFoundPage = () => {

  useEffect(() => {
    anime({
      targets: '#svg',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 2000,
      delay: function(el, i) { return i * 250 },
      direction: 'alternate',
      loop: false
    });
  })

  return(
    <Layout>
    <SEO title="404" />

    <div id="wrapper404" className="flex flex-col justify-center items-center py-24">
      <Illu className="w-full md:h-64" id="svg"/>
      <h1 className="text-3xl mt-10">
        Die Seite nach der du suchst existiert leider nicht mehr.
      </h1>
    </div>
  </Layout>
  )
  
}

export default NotFoundPage
